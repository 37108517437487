import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllShifts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/shift/Shift')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    createShift(ctx, shiftData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/shift/Shift/Create', shiftData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateShift(ctx, shiftData) {
      return new Promise((resolve, reject) => {
        axios
          .put('pamis/admin/shift/Shift/Update', shiftData, { params: { id: shiftData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteShift(ctx, shiftData) {
      return new Promise((resolve, reject) => {
        axios
          .delete('pamis/admin/shift/Shift/Delete', { params: { id: shiftData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
