<template>
  <b-sidebar id="add-new-shift-sidebar"
             :visible="isAddNewShiftSidebarActive"
             bg-variant="white"
             sidebar-class="sidebar-lg"
             shadow
             backdrop
             no-header
             right
             @hidden="resetForm"
             @change="(val) => $emit('update:is-add-new-shift-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ actionType == 0 ? " Add New Shift" : actionType == 1 ? " Update Shift" : "Delete Shift" }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(submitForm)" @reset.prevent="resetForm">

          <validation-provider #default="validationContext"
                               name="Name"
                               rules="required">
            <b-form-group label="Name"
                          label-for="full-name">
              <b-form-input id="full-name"
                            v-model="currentShift.name" autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            :disabled="actionType == 2"
                            placeholder="Name" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext"
                               name="StartTime"
                               rules="required">
            <b-form-group label="StartTime"
                          label-for="start-time">
              <b-form-input id="start-time"
                            v-model="currentShift.startTime" autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            type="time"
                            :disabled="actionType == 2"
                            step="1"
                            placeholder="Start Time" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext"
                               name="EndTime"
                               rules="required">
            <b-form-group label="EndTime"
                          label-for="end-time">
              <b-form-input id="end-time"
                            v-model="currentShift.endTime" autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            type="time"
                            :disabled="actionType == 2"
                            step="1"
                            placeholder="End Time" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext"
                               name="Sequence"
                               rules="required">
            <b-form-group label="Sequence"
                          label-for="sequence">
              <b-form-input id="sequence"
                            type="number"
                            v-model="currentShift.sequence" autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            :disabled="actionType == 2"
                            placeholder="sequence" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider name="Is Active">
            <b-form-group label=""
                          label-for="isActive">
              <b-form-checkbox class="mt-2"
                               id="isActive"
                               :disabled="actionType == 2"
                               v-model="currentShift.isActive">
                Is Active
              </b-form-checkbox>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
                      v-if="currentShift == null || currentShift.id == null">
              Add
            </b-button>

            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="updateshift"
                      v-if="currentShift != null && currentShift.id != null && actionType == 1">
              Update
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" class="mr-2"
                      v-if="currentShift != null && currentShift.id != null && actionType == 2" variant="outline-danger"
                      @click="deleteshift">
              Delete
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton, BCardText
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BCardText,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewShiftSidebarActive',
    event: 'update:is-add-new-shift-sidebar-active',
  },
  props: {
    isAddNewShiftSidebarActive: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: Number,
      required: true,
    },
  },
  methods: {
    deleteshift() {
      this.$store.dispatch('apps-shift/deleteShift', this.currentShift)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-shift-sidebar-active', false)
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Delete Shift Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    updateshift() {
      this.$store.dispatch('apps-shift/updateShift', this.currentShift)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-shift-sidebar-active', false)
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update Shift Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    submitForm() {
      this.$store.dispatch('apps-shift/createShift', this.currentShift)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-shift-sidebar-active', false)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Create Shift Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    }
  },

  watch: {
    shiftdata: {
      handler: function (n, o) {
        if (n == null || n == undefined) {
          this.currentShift = {
            id: null,
            name: null,
            startTime: null,
            endTime: null,
            sequence:null,
            isActive: null
          }
        } else {
          this.currentShift = n.selectedShift;
        }
      },
      deep: true
    }

  },
  data() {
    return {
      required,
      currentShift: null
    }
  },
  inject: ['shiftdata'],
  setup(props, { emit }) {
    const blankShiftData = {
      id: null,
      name: null,
      startTime: null,
      endTime: null,
      sequence:null,
      isActive: null
    }
    const shiftData = ref(JSON.parse(JSON.stringify(blankShiftData)))
    const {
      getValidationState,
    } = formValidation(shiftData)

    return {
      shiftData,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-shift-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
